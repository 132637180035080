import React, { useEffect, useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';
import ApprovalStatus from '../constants/ApprovalStatus';
import RentalStatus from '../constants/RentalStatus';
import UnitType from '../constants/UnitType';

import BannerImg from '../custom/images/banner.png';
import LoginIconImg from '../custom/images/login.svg';
import MypageIconImg from '../custom/images/mypage.svg';
import CalendarImg from '../custom/images/calendar.svg';
import Row1BackgroundImg from '../custom/images/row1Background.png';
import Row2BackgroundImg from '../custom/images/row2Background.png';
import Row1ArrowIconImg from '../custom/images/row1Arrow.svg';
import Row1_1Img from '../custom/images/row1-1.png';
import Row1_2Img from '../custom/images/row1-2.png';
import Row1_3Img from '../custom/images/row1-3.png';
import Row1_4Img from '../custom/images/row1-4.png';
import Row1_5Img from '../custom/images/row1-5.png';
import Row2_1Img from '../custom/images/row2-1.svg';
import Row2_2Img from '../custom/images/row2-2.svg';
import Row2_3Img from '../custom/images/row2-3.svg';
import Row2_4Img from '../custom/images/row2-4.svg';

import * as api from '../apis';

const Main = () => {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	const [bookingList, setBookingList] = useState(null);

	const roomList = [
		{ id: 1, name: '스튜디오', image: Row1_1Img },
		{ id: 2, name: '호리존 스튜디오', image: Row1_2Img },
		{ id: 3, name: '셀프 스튜디오', image: Row1_3Img },
		{ id: 4, name: '화상 회의실', image: Row1_4Img },
		{ id: 5, name: 'ALC 강의실', image: Row1_5Img },
	];

	const productList = [
		{ id: 11, name: '웹캠', image: Row2_1Img },
		{ id: 12, name: 'USB 마이크', image: Row2_2Img },
		{ id: 13, name: '와콤 타블렛', image: Row2_3Img },
		{ id: 14, name: '스마트폰 삼각대', image: Row2_4Img },
	];

	useEffect(() => {
		if (user) {
			let filterParams = [`page_size=2`];
			api.getMyBooking(filterParams).then((res) => {
				if (res && res.data) {
					setBookingList(res.data);
				}
			});
		}
	}, [user]);

	const onClickLogin = () => {
		navigate('/login');
	};

	const onClickMypage = () => {
		navigate('/mypage');
	};

	const onClickBooking = (type) => {
		if (!user) {
			navigate('/login');
			return;
		}
		navigate(`/booking`, {
			state: {
				unit_type: type,
			},
		});
	};

	const onClickRoomUnitBooking = (unitId) => {
		if (!user) {
			navigate('/login');
			return;
		}
		navigate(`/booking/schedule/${unitId}`);
	};

	const onClickProductUnitBooking = (unitId) => {
		if (!user) {
			navigate('/login');
			return;
		}
		navigate(`/booking/product/schedule/${unitId}`);
	};

	const renderMyBooking = () => {
		if (!bookingList || bookingList.items.length === 0) {
			return <NoBookingList>예약 내역이 없습니다.</NoBookingList>;
		}
		return (
			<MyBookingContainer>
				{bookingList?.items.map((booking, idx) => {
					const bookingUnitType = booking.unit.unit_type_name;
					const locationDetail =
						bookingUnitType === UnitType.ROOM
							? `${booking.unit.name} ${booking.room_item.name}`
							: `${booking.unit.name}`;
					return renderBookingDetailRow(
						idx,
						bookingUnitType,
						booking.approval_status,
						booking.rental_status,
						booking.booking_date.split('~')[0],
						locationDetail
					);
				})}
			</MyBookingContainer>
		);
	};

	const renderBookingDetailRow = (idx, type, approvalStatus, rentalStatus, calendarDetail, locationDetail) => {
		return (
			<MyContentsRow key={idx}>
				<BookingStatusBox status={approvalStatus}>
					<span>{ApprovalStatus.toString(approvalStatus)}</span>
				</BookingStatusBox>
				<BookingDetailContainer>
					<BookingDetailRow>
						<BookingType type={type}>{UnitType.toString(type)}</BookingType>
						{type === UnitType.PRODUCT && (
							<RentalStatusBox status={rentalStatus}>
								{RentalStatus.toString(rentalStatus)}
							</RentalStatusBox>
						)}
					</BookingDetailRow>
					<BookingDetailRow column>
						<BookingDetailText>{calendarDetail ?? '-'}</BookingDetailText>
						<BookingLocationDetailText>{locationDetail ?? '-'}</BookingLocationDetailText>
					</BookingDetailRow>
				</BookingDetailContainer>
			</MyContentsRow>
		);
	};

	const renderRoomBookingItem = () => {
		return roomList.map((room, idx) => (
			<BodyRow1Item key={idx} background={room.image} onClick={() => onClickRoomUnitBooking(room.id)}>
				<Row1ItemText>{room.name}</Row1ItemText>
				<Row1ItemHover>
					<Row1ItemHoverText>{room.name}</Row1ItemHoverText>
					<Row1ItemReservation>
						<Row1ItemReservationText>예약하기</Row1ItemReservationText>
						<Row1ItemReservationArrowIcon src={Row1ArrowIconImg} />
					</Row1ItemReservation>
				</Row1ItemHover>
			</BodyRow1Item>
		));
	};

	const renderProductBookingItem = () => {
		return productList.map((product, idx) => (
			<BodyRow2Item key={idx} onClick={() => onClickProductUnitBooking(product.id)}>
				<Row2ItemText>{product.name}</Row2ItemText>
				<Row2ItemIconContainer>
					<Row2ItemIcon src={product.image} />
				</Row2ItemIconContainer>
				<Row2ItemHover />
			</BodyRow2Item>
		));
	};

	return (
		<Container>
			<BannerBackground src={BannerImg}>
				<BannerContainer>
					<MyContainer>
						<MyTopContainer>
							{user ? (
								<>
									<MyStatusTitle>내 예약 현황</MyStatusTitle>
									{renderMyBooking()}
								</>
							) : (
								<>
									<MyStatusTitle>내 예약 현황</MyStatusTitle>
									<MyStatusLoginInfo>로그인 후 이용해 주세요.</MyStatusLoginInfo>
								</>
							)}
						</MyTopContainer>
						<MyBottomContainer>
							{user ? (
								<BottomButtonContainer onClick={onClickMypage}>
									<BottomText>마이페이지</BottomText>
									<BottomButtonIconContainer>
										<BottomButtonIcon src={MypageIconImg} />
									</BottomButtonIconContainer>
								</BottomButtonContainer>
							) : (
								<BottomButtonContainer onClick={onClickLogin}>
									<BottomText>로그인</BottomText>
									<BottomButtonIconContainer>
										<BottomButtonIcon src={LoginIconImg} />
									</BottomButtonIconContainer>
								</BottomButtonContainer>
							)}
						</MyBottomContainer>
						<CalendarIcon src={CalendarImg} login={user ? true : false} />
					</MyContainer>
				</BannerContainer>
			</BannerBackground>
			<BodyContainer>
				<BodyContainerRow>
					<BodyRowLeftBlank backgroundColor={'#22499d'}></BodyRowLeftBlank>
					<BodyRow>
						<BodyRowLeft background={Row1BackgroundImg} backgroundColor={'#22499d'}>
							<BodyLeftTitle>시설예약</BodyLeftTitle>
							<BodyLeftMore onClick={() => onClickBooking(UnitType.ROOM)}>
								<BodyLeftMoreText>바로가기</BodyLeftMoreText>
								<BodyLeftMoreButton row={1}>+</BodyLeftMoreButton>
							</BodyLeftMore>
						</BodyRowLeft>
						<BodyRowRight row={1}>{renderRoomBookingItem()}</BodyRowRight>
					</BodyRow>
					<BodyRowRightBlank backgroundColor={'#fff'}></BodyRowRightBlank>
				</BodyContainerRow>
				<BodyContainerRow>
					<BodyRowLeftBlank backgroundColor={'#00b1a6'}></BodyRowLeftBlank>
					<BodyRow>
						<BodyRowLeft background={Row2BackgroundImg} backgroundColor={'#00b1a6'}>
							<BodyLeftTitle>장비예약</BodyLeftTitle>
							<BodyLeftMore onClick={() => onClickBooking(UnitType.PRODUCT)}>
								<BodyLeftMoreText>바로가기</BodyLeftMoreText>
								<BodyLeftMoreButton row={2}>+</BodyLeftMoreButton>
							</BodyLeftMore>
						</BodyRowLeft>
						<BodyRowRight row={2}>{renderProductBookingItem()}</BodyRowRight>
					</BodyRow>
					<BodyRowRightBlank backgroundColor={'#F1F3F6'}></BodyRowRightBlank>
				</BodyContainerRow>
			</BodyContainer>
		</Container>
	);
};

const Container = styled.div`
	width: 100%;
	height: 100%;
`;

const BannerBackground = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;

	position: relative;
	width: 100%;
	height: 316px;
	top: 0px;

	${(props) =>
		props.src &&
		css`
			background-image: url(${props.src});
			background-repeat: no-repeat;
			background-size: cover;
		`}

	@media only screen and (max-width: 1280px) {
		height: 438px;
	}

	@media only screen and (max-width: 480px) {
		height: 354px;
	}
`;

const BannerContainer = styled.div`
	width: 100%;
	height: 100%;
	max-width: 1240px;

	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex-direction: row;

	@media only screen and (max-width: 768px) {
		flex-direction: column-reverse;
		justify-content: center;
		align-items: flex-end;
		padding: 20px;
	}
`;

const MyContainer = styled.div`
	width: 552px;
	height: 278px;
	position: relative;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #22499d;
	border-radius: 0 60px 0 0;

	@media only screen and (max-width: 1280px) {
		margin-right: 40px;
	}

	@media only screen and (max-width: 768px) {
		margin-right: 0px;
	}

	@media only screen and (max-width: 480px) {
		width: 100%;
		height: 100%;
	}
`;

const MyTopContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex: 1;

	@media only screen and (max-width: 480px) {
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		gap: 24px;
	}
`;

const CalendarIcon = styled.img`
	position: absolute;
	bottom: 0;

	@media only screen and (max-width: 480px) {
		${(props) =>
			props.login &&
			css`
				display: none;
			`}
	}
`;

const NoBookingList = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;
`;

const MyBookingContainer = styled.div`
	@media only screen and (max-width: 480px) {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: 0 16px;
	}
`;

const MyContentsRow = styled.div`
	height: 73px;
	display: flex;

	& + & {
		margin-top: 15px;
	}
`;

const BookingStatusBox = styled.div`
	width: 73px;
	height: 73px;
	flex-shrink: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 36px;
	border: 7px solid rgba(255, 255, 255, 0.8);

	span {
		width: 30px;
		text-align: center;
		font-family: 'Pretendard';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #ffffff;
	}
`;

const BookingDetailContainer = styled.div`
	margin-left: 16px;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
`;

const BookingDetailRow = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;

	& + & {
		margin-top: 7px;
	}

	${(props) =>
		props.column &&
		css`
			flex-direction: column;
			align-items: flex-start;
		`}
`;

const BookingType = styled.div`
	width: 49px;
	height: 26px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;

	${(props) => {
		switch (props.type) {
			case UnitType.ROOM:
				return css`
					border: 1px solid #85d9ff;
					color: #85d9ff;
				`;
			case UnitType.PRODUCT:
				return css`
					border: 1px solid #fff7d6;
					color: #fff7d6;
				`;
			default:
				return css`
					border: 1px solid #fff7d6;
					color: #fff7d6;
				`;
		}
	}};
`;

const RentalStatusBox = styled.div`
	margin-left: 5px;
	width: 70px;
	height: 26px;
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;

	${(props) => {
		switch (props.status) {
			case RentalStatus.RENTAL_READY:
				return css`
					background-color: #00c6ba;
				`;
			case RentalStatus.RENTAL_DELAY:
				return css`
					background-color: #e7683f;
				`;
			case RentalStatus.RETURN_DELAY:
				return css`
					background-color: #ff3535;
				`;
			case RentalStatus.RENTAL_COMPLETE:
			case RentalStatus.RETURN_COMPLETE:
				return css`
					background-color: #0d93f4;
				`;
			default:
				return css`
					background-color: #00c6ba;
				`;
		}
	}};
`;

const BookingDetailText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: #ffffff;
`;

const BookingLocationDetailText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;

	width: 234px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media only screen and (max-width: 480px) {
		width: calc(100dvw - 155px);
	}
`;

const MyBottomContainer = styled.div`
	width: 100%;
	height: 65px;
	display: flex;
	justify-content: flex-end;
`;

const MyStatusTitle = styled.span`
	margin: 0 24px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 38px;
	color: #ffffff;
	white-space: nowrap;

	@media only screen and (max-width: 480px) {
		margin: 0 16px;
	}
`;

const MyStatusLoginInfo = styled.span`
	flex: 1;
	text-align: center;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;

	@media only screen and (max-width: 480px) {
		flex: 0;
		margin: 0 16px;
	}
`;

const BottomButtonContainer = styled.div`
	cursor: pointer;
	width: 350px;
	height: 100%;
	display: flex;
	align-items: center;
	z-index: 1;

	@media only screen and (max-width: 480px) {
		width: 100%;
		padding-left: 0;
	}
`;

const BottomText = styled.span`
	width: 285px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 29px;
	color: #ffffff;
	background-color: #00b1a6;

	@media only screen and (max-width: 480px) {
		width: calc(100% - 65px);
	}
`;

const BottomButtonIconContainer = styled.div`
	width: 65px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #008a82;
`;

const BottomButtonIcon = styled.img`
	@media only screen and (max-width: 480px) {
		width: 22px;
		height: 22px;
		object-fit: contain;
	}
`;

const BodyContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const BodyContainerRow = styled.div`
	display: flex;
	width: 100%;
	height: 260px;

	@media only screen and (max-width: 768px) {
		height: auto;
		flex-direction: column;
	}
`;

const BodyRow = styled.div`
	width: 100%;
	max-width: 1240px;
	display: flex;

	@media only screen and (max-width: 768px) {
		flex-direction: column;
	}
`;

const BodyRowLeft = styled.div`
	flex-shrink: 0;
	width: 211px;
	height: 100%;
	${(props) =>
		props.background &&
		css`
			background-image: url(${props.background});
		`};
	background-position: right center;
	background-size: cover;
	background-repeat: no-repeat;

	@media only screen and (max-width: 768px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 66px;
		padding: 20px;
		background-image: none;
		background-color: ${(props) => props.backgroundColor};
	}
`;

const BodyLeftTitle = styled.div`
	padding-top: 59px;
	padding-right: 90px;
	text-align: right;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 26px;
	line-height: 31px;
	color: #ffffff;

	@media only screen and (max-width: 768px) {
		padding: 0;
		font-size: 22px;
		line-height: 26px;
	}
`;

const BodyLeftMore = styled.div`
	margin-top: 13px;
	padding-right: 90px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	cursor: pointer;

	@media only screen and (max-width: 768px) {
		margin: 0;
		padding: 0;
	}
`;

const BodyLeftMoreText = styled.span`
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;

	@media only screen and (max-width: 768px) {
		font-size: 14px;
		line-height: 17px;
	}
`;

const BodyLeftMoreButton = styled.div`
	margin-left: 8px;
	width: 28px;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 14px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 300;
	font-size: 28px;
	line-height: 28px;
	color: #ffffff;
	padding-bottom: 5px;

	${(props) => {
		switch (props.row) {
			case 1:
				return css`
					background-color: #00b1a6;
				`;
			case 2:
				return css`
					background-color: #22499d;
				`;
			default:
				return css`
					background-color: #00b1a6;
				`;
		}
	}};

	@media only screen and (max-width: 768px) {
		width: 25px;
		height: 25px;
	}
`;

const BodyRowRight = styled.div`
	display: flex;
	align-items: center;
	flex-grow: 1;
	${(props) => {
		switch (props.row) {
			case 1:
				return css`
					background-color: #ffffff;
				`;
			case 2:
				return css`
					background-color: #f1f3f6;
				`;
			default:
				return css`
					background-color: #ffffff;
				`;
		}
	}};

	@media only screen and (max-width: 768px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 16px;
		padding: 20px;
	}
`;

const BodyRowLeftBlank = styled.div`
	flex-grow: 1;
	${(props) =>
		props.backgroundColor &&
		css`
			background-color: ${props.backgroundColor};
		`};
`;

const BodyRowRightBlank = styled.div`
	flex-grow: 1;
	${(props) =>
		props.backgroundColor &&
		css`
			background-color: ${props.backgroundColor};
		`};
`;

const Row1ItemHover = styled.div`
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	width: 189px;
	height: 200px;
	border: 10px solid #375fb6;
	box-shadow: 0px 4px 8px 0px #8b98ac80;
	background-color: #ffffffb2;

	@media only screen and (max-width: 768px) {
		width: 100%;
	}
`;

const Row1ItemHoverText = styled.div`
	font-family: 'Pretendard';
	font-size: 20px;
	font-weight: 700;
	line-height: 23px;
	color: #000000;
`;

const Row1ItemReservation = styled.div`
	display: flex;
	align-items: center;
	gap: 2px;
`;

const Row1ItemReservationText = styled.div`
	font-family: 'Pretendard';
	font-size: 14px;
	font-weight: 600;
	line-height: 16px;
	color: #375fb6;
`;

const Row1ItemReservationArrowIcon = styled.img``;

const Row1ItemText = styled.div`
	margin-bottom: 20px;
	font-family: 'Pretendard';
	font-size: 20px;
	font-weight: 600;
	line-height: 23px;
	color: #ffffff;
`;

const BodyRow1Item = styled.div`
	position: relative;
	margin-left: 20px;
	width: 189px;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	${(props) =>
		props.background &&
		css`
			background-image: url(${props.background});
		`};
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	cursor: pointer;

	& + & {
		margin-left: 16px;
	}

	&:hover {
		& > ${Row1ItemHover} {
			display: flex;
		}

		& > ${Row1ItemText} {
			display: none;
		}
	}

	@media only screen and (max-width: 768px) {
		margin-left: 0px;
		width: 100%;

		& + & {
			margin-left: 0px;
		}
	}
`;

const Row2ItemText = styled.div`
	padding-top: 32px;
	padding-left: 30px;
	font-family: 'Pretendard';
	font-style: normal;
	font-weight: 600;
	font-size: 22px;
	line-height: 26px;
	color: #000000;

	@media only screen and (max-width: 768px) {
		padding: 0;
		font-size: 20px;
		line-height: 24px;
	}
`;

const Row2ItemIconContainer = styled.div`
	@media only screen and (max-width: 768px) {
		width: 44px;
		align-self: flex-end;
	}
`;

const Row2ItemIcon = styled.img`
	margin-top: 68px;
	margin-left: 156px;

	@media only screen and (max-width: 768px) {
		margin: 0;
	}
`;

const Row2ItemHover = styled.div`
	display: none;
	position: absolute;
	left: 0;
	top: 0;
	width: 240px;
	height: 200px;
	border: 10px solid #375fb6;
	box-shadow: 0px 4px 8px 0px #8b98ac80;

	@media only screen and (max-width: 768px) {
		width: 100%;
	}
`;

const BodyRow2Item = styled.div`
	position: relative;
	margin-left: 20px;
	width: 240px;
	height: 200px;
	background-color: #ffffff;
	cursor: pointer;

	& + & {
		margin-left: 16px;
	}
	&:hover > ${Row2ItemText} {
		color: #22499d;
	}
	&:hover > ${Row2ItemHover} {
		display: flex;
	}

	@media only screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 30px;
		width: 100%;
		margin: 0;

		& + & {
			margin: 0;
		}
	}
`;

export default Main;
