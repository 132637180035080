const CustomResource = {
	univ_name: '숭실대학교',
	site_title: '',
	sso_login: '숭실대학교 LMS ID/PW로 로그인합니다.',
	address1: '06978 서울시 동작구 상도로 369 숭실대학교',
	address2: '',
	contact: '대표전화 : 02-820-0114',
	copyright: '© SOONGSIL UNIVERSITY. ALL RIGHT RESRERVED.',
};

export default CustomResource;
